import { Card, Col, Container, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Helmet } from "react-helmet";
import "./home.css";
import HomeCarousel from "../../components/carousel/Carousel";
import { VideoContainer } from "../../components/layout/containers/Container";
import Button from "../../components/button/Button";

const styles = {
    customBody: {
        // backgroundColor: 'rgb(247 249 248)',
        border: "none",
    },
};

const Home = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => { };
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const [video, setVideo] = useState(false);

    let title = "Digihappy | Senioripuhelin, senioritabletti ja turvaranneke palveluna ";
    let description = "Digihappy on helppo senioritabletti, senioripuhelin tai turvaranneke palveluna. Videopuhelut ilman kosketusta. It-tuki.";
    if (window.location.host.split(".")[0] === "www") {
        title += " | WWW versio";
        description += " - WWW versio.";
    } else {
        description += ".";
    }

    const products = [
        {
            title: "SENIORITABLETTI",
            image: "/assets/icons/tablet.png",
            description: "Helpoin videoyhteys seniorille ja perheelle. Videopuhelut avautuvat myös itsestään. Kivaa tekemistä yksilöllisesti.",
            items: [
                "Muokattu Samsung-tabletti ja kotinäkymä",
                "Liittymä, jalusta ",
                "Videopuhelut myös ilman kosketusta Digihappy apilla",
                "Turvallinen perhetili videopuheluihin",
                "Halutut apit, kuten äänikirjat, uutiset, pasianssi",
                "Kaikki valmiina palveluna ja jatkuva it-tuki!",
            ],
        },
        {
            title: "SENIORIPUHELIN",
            image: "/assets/icons/mobile.png",
            description: "Paras älypuhelin seniorille. Kaikki tarpeellinen, ei turhaa.",
            items: [
                "Muokattu Samsung-puhelin ja kotinäkymä ",
                "Liittymä, jalusta",
                "Videopuhelut myös ilman kosketusta Digihappy apilla",
                "Turvallinen perhetili videopuheluihin",
                "Halutut toiminnot, normaalit puhelut, tekstiviestit, apit",
                "Kaikki valmiina palveluna ja jatkuva it-tuki! ",
            ],
        },
        {
            title: "TURVARANNEKE",
            image: "/assets/icons/watch.png",
            description: "Paras turva vanhukselle. Napin painalluksella hälytys ja puheyhteys. Gps-paikannus.",
            items: ["Turvaranneke tai kaulamalli", "Liittymä. Toimii ilman kännykkää.", "Paikantava turvaranneke ja turvapuhelin.", "Hälytys seniorin poistuessa alueelta tai kaatuessa", "Kaikki valmiina palveluna ja it-tuki!"],
        },
    ];

    const carouselItems = [
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Mummu on aina ilahtunut videopuheluista ja hänen ei ole tarvinnut osata tehdä mitään"',
            author: "Tytär, joka asuu Ruotsissa.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Isäni rauhoittui, kun hän kuunteli äänikirjoja Digihappyn avulla"',
            author: "Poika, jonka isä oli ennen sairastumistaan tottunut lukemaan paljon kirjoja.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappy helpottaa paljon, kun voimme videopuheluilla varmistaa, että isämme ottaa lääkkeensä"',
            author: "Perhe, jonka jäsenet soittavat videopuhelun vuoropäivinä yksin asuvalle 85- vuotiaalle isälleen.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappy on tuonut meille paljon iloa. Soitin miehelleni sen avulla videopuhelun kolmesti päivässä. Olen suositellut sitä ystävillenikin!"',
            author: "85- vuotias rouva, joka soitti videopuheluita iäkkäälle miehelleen Digihappylla.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Olemme etsineet vuoden tällaista palvelua"',
            author: "Tytär, joka soittaa päivittäin Digihappy videopuhelun äidilleen.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappyn palvelu on ystävällistä ja asiantuntevaa. Apua saa henkilökohtaisesti."',
            author: "Poika, jonka äidille tehtiin henkilökohtaisia asetuksia tablettiin.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Hankimme iäkkäälle isälleni Digihappyn alkuvuodesta - se on aivan loistava"',
            author: "Tytär, joka puhuu videopuheluja 90- vuotiaan isän kanssa.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Turvapuhelimen avulla minut pystyttiin tavoittamaan ja paikantamaan helposti, kun olin kaatunut."',
            author: "Mies, joka soitti turvarannekkeella kaaduttuaan.",
        },
    ];
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {/* <TextOnImage
                height={`calc(100vh - ${
                    56 +
                    56 *
                        list?.filter((el) => {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
                width="100vw"
                text1={texts[7].text1}
                text2={texts[7].text2}
                backgroundImage={homepage}
                backgroundSize="cover"
                backgroundPosition="center top"
                backgroundRepeat="no-repeat"
                imgClassName="girlpic"
                className1="girlpic-text"
                className2="girlpic-link"
            /> */}

            <Container
                fluid
                style={{
                    height: `calc(100vh - ${56 +
                        56 *
                        list?.filter((el) => {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                        1
                        }px)`,
                    width: "100vw",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('/assets/images/home/digihappy_main.png')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    minHeight: 640,
                }}
                aria-label="Nainen käyttää tablettia, jonka näytöllä näkyy yhteystietoluettelo."
                className="img-fluid contact-pic"
            >
                <h3 className="global-banner-title home-pic-title">Digihappy seniorille</h3>
                <p className="global-banner-text home-pic-text">Koska välität</p>
            </Container>

            <Container className="text-container">
                <h5 className="header-under-pic">Digihappy seniorille</h5>
                <div className="header-div">
                    <i className="fas fa-users users-ikoni" />
                    <h1 className="header-text first-text" style={{ textAlign: "center" }}>
                        Digihappy - paras tabletti, puhelin ja turvaranneke seniorille. Videopuhelut ilman kosketusta ja kivaa tekemistä. Helpot hälytykset. Aina valmiina palveluna ja <span style={{ whiteSpace: "nowrap" }}>it-tuki.</span>{" "}
                        <br />
                        Suomi, ruotsi, englanti.
                    </h1>
                </div>
            </Container>
            <section className="image-rows">
                <div className="home-grid-container">
                    {products.map((product, key) => (
                        <div className="home-grid-column" key={key}>
                            <ProductCard product={product} />
                        </div>
                    ))}
                </div>
            </section>
            <section className="home-carousel-container">
                <HomeCarousel items={carouselItems} />
            </section>
            <section className="image-rows">
                <Row noGutters>
                    <Col sm={12} md={12} lg={6} className="home-column">
                        <Card className="home-image-card manfamily">
                            <Card.Img src={"/assets/images/home/helpot-videopuhelut-senioripuhelimella.webp"} variant="top" height="100vh" width="auto" alt="Uusi Samsung Galaxy S10 5G ja kosketusnäyttökynä pöydällä. " />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as="div" className="image-text text-center">
                                    Helppo videopuhelu puhelimessa ja tabletissa. Vastaus haluttaessa ilman kosketusta. Soitto painamalla läheisen kuvaa.
                                </Card.Title>
                                <Card.Text as="div" className="image-link text-center">
                                    <HashLink to="/senioripuhelin" className="lue-lisää-linkki">
                                        <strong>Lue lisää</strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className="home-column">
                        <Card className="home-image-card woman2">
                            <Card.Img src={"/assets/images/home/peli-senoritabletilla.webp"} variant="top" height="550" width="auto" alt="Seniori pelaa korttipeliä tabletilla" />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as="div" className="image-text text-center">
                                    Muokattu kotinäkymä ja ominaisuudet tabletissa ja puhelimessa. Eri vaihtoehdot seniorille, joka ei pysty koskemaan laitteeseen ja aktiiviselle seniorille.
                                </Card.Title>

                                <Card.Text as="div" className="image-link text-center">
                                    <HashLink to="/palvelut" className="lue-lisää-linkki">
                                        <strong>Lue lisää</strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <section className="between-image-rows" />
                <Row noGutters>
                    <Col sm={12} md={12} lg={6} className="home-column">
                        <Card className="home-image-card it-woman">
                            <div className="global-credit-image-container">
                                <Card.Img src={"/assets/images/home/paras-turvaranneke.webp"} variant="top" height="550" width="auto" alt="Henkilö pitelee ovenkahvaa, ranteessaan musta kello." />
                                <span className="global-credit-text">BILD: : Björn Johansson/Posifon</span>
                            </div>
                            <Card.Body style={styles.customBody}>
                                <Card.Title as="div" className="image-text text-center">
                                    Napin painalluksella hälytys ja puheyhteys läheisiin. Gps-paikannus, joka ei ole sidottu seniorin kännykkään. Alue-kaatumis- ja akku loppuu hälytys.
                                </Card.Title>
                                <Card.Text as="div" className="image-link text-center">
                                    <HashLink to="/turvaranneke" className="lue-lisää-linkki">
                                        <strong>Lue lisää</strong>
                                    </HashLink>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} className="home-column">
                        <Card className="home-image-card doctor1">
                            <Card.Img src={"/assets/images/home/4.jpg"} variant="top" height="550" width="auto" />

                            <Card.Body style={styles.customBody}>
                                <Card.Title as="div" className="image-text text-center">
                                    Palveluna valmiit asetukset, yhteystiedot, kotinäkymä, sovellukset ja liittymä. Alkuneuvonta, jatkuva it-tuki, etähallinta ja tietoturva.
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <section className="between-image-rows" />
            </section>

            {/*<TextOnImage*/}
            {/*    text1={texts[0].text1}*/}
            {/*    // text2={texts[0].text2}*/}
            {/*    text3={texts[0].text3}*/}
            {/*    link={texts[0].link}*/}
            {/*    height='120vh'*/}
            {/*    width='100vw'*/}
            {/*    backgroundImage={*/}
            {/*      'https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/bottompic.jpg'*/}
            {/*    }*/}
            {/*    backgroundSize='cover'*/}
            {/*    backgroundPosition='center center'*/}
            {/*    backgroundRepeat='no-repeat'*/}
            {/*    backgroundAttachment=''*/}
            {/*    imgClassName='bottompic'*/}
            {/*    className1='bottompic-text'*/}
            {/*    className2='bottompic-link'*/}
            {/*    className3='bottom-link'*/}
            {/*/>*/}

            <section className="image-rows" style={{ marginBottom: 48 }}>
                <Row className="justify-content-md-center">
                    <Col sm={12} md={12} lg={8} className="home-column">
                        <Card className="home-image-card doctor1">
                            <Card.Body style={styles.customBody}>
                                <Card.Title
                                    as="div"
                                    className="image-text text-center"
                                    style={{
                                        marginBottom: "1.6rem",
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            if (!video) {
                                                setVideo(true);
                                            }
                                        }}
                                    >
                                        Katso video
                                    </Button>
                                </Card.Title>
                                <VideoContainer>
                                    {video && (
                                        <iframe
                                            src={`https://www.youtube.com/embed/bg_ORIbrHCg?autoplay=1&rel=0&color=white&modestbranding=1`}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="Digihappy Videon"
                                            className="global-youtube-video"
                                        />
                                    )}
                                    <div className="global-video-overlay" style={{ opacity: video ? 0 : 1, pointerEvents: video ? "none" : "auto" }}>
                                        <img
                                            id="video-cover"
                                            src={"/assets/images/home/digihappy videon pikkukuva.png"}
                                            alt="Digihappy Videon Pikkukuva"
                                            style={{
                                                height: "auto",
                                                width: "100%",
                                            }}
                                        />
                                        <button
                                            id="play"
                                            className="play-btn"
                                            onClick={() => {
                                                setVideo(true);
                                            }}
                                        >
                                            <i
                                                className="fas fa-play global-hover-zoom"
                                                style={{
                                                    color: "#ffffff",
                                                    fontSize: "min(6rem, 15vw)",
                                                }}
                                            />
                                        </button>
                                    </div>
                                </VideoContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </>
    );
};

const ProductCard = ({ product }: { product: { title: string; image: string; description: string; items: string[] } }) => {
    return (
        <Card className="home-card-2024">
            <Card.Title className="image-text text-center home-card-title">{product.title}</Card.Title>
            <img src={product.image} alt="T" className="home-card-image" />
            <Card.Text className="image-text text-center home-card-text home-card-description">{product.description}</Card.Text>
            <Card.Text as="div" className="image-text text-center home-card-text home-card-list">
                {product.items.map((item, key) => (
                    <div className="home-card-list-item" key={key}>
                        <i className="fas fa-check-circle" />
                        <span className="image-text home-card-text">{item}</span>
                    </div>
                ))}
            </Card.Text>
        </Card>
    );
};

export default Home;
